import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { RouterProvider } from 'react-router-dom'

import Backdrop from './component/Backdrop';
import SnackbarAlertProvider from './component/SnackbarAlertProvider';
import Auth from './domain/auth';
import { router } from './router';
import { AuthProvider } from './state/auth';
import { CartProvider } from './state/cart';
import { CouponProvider } from './state/coupon';
import { LoadingProvider } from './state/loading';
import { OrderProvider } from './state/order';
import { PosProvider, useRefreshInventory, useRefreshPos } from './state/pos';
import { UserProvider } from './state/user';
import { theme } from './theme';

const useLoad = () => {
  useRefreshPos();
  useRefreshInventory();
}

function StateFullApp() {
  useLoad();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Backdrop />
      <RouterProvider router={router} />
      <Auth />
    </ThemeProvider>
  )
}

function App() {
  return (
    <LoadingProvider>
      <SnackbarAlertProvider>
        <AuthProvider>
          <UserProvider>
            <PosProvider>
              <CartProvider>
                <CouponProvider>
                  <OrderProvider>
                    <StateFullApp />
                  </OrderProvider>
                </CouponProvider>
              </CartProvider>
            </PosProvider>
          </UserProvider>
        </AuthProvider>
      </SnackbarAlertProvider>
    </LoadingProvider>
  );
}

export default App;
