interface PersistOptions {
  overwrite?: boolean;
}

export function persist<T>(key: string, item: Partial<T>, options?: PersistOptions) {
  const current: Partial<T> = JSON.parse(localStorage.getItem(key) || '{}');
  if (options?.overwrite) {
    return localStorage.setItem(key, JSON.stringify(item));
  }
  localStorage.setItem(key, JSON.stringify({ ...current, ...item }));
}

export const persistKeys = {
  auth: 'hac-state-auth',
  user: 'hac-state-user',
  cart: 'hac-state-cart',
  pos: 'hac-state-pos',
  coupon: 'hac-state-coupon',
  order: 'hac-state-order',
}