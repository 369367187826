export enum MappedEvents {
  LoginAttempt = 'login_attempt',
}

export class EventHub {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static emit(event: MappedEvents, data?: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }

  static on(event: MappedEvents, callback: (event: CustomEvent) => void) {
    document.addEventListener(event, callback as EventListener);
  }

  static off(event: MappedEvents, callback: (event: CustomEvent) => void) {
    document.removeEventListener(event, callback as EventListener);
  }
}
