
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useLogoutMutation } from '../api/auth';
import { useFetchUser, useUpdatePasswordMutation, useUpdateUserMutation } from '../api/users';
import useAlert from '../hooks/useAlert';
import { UpdatePasswordRequest, UpdateUserRequest, UserGender } from '../model/user';
import { ProfileAlertStates } from '../utils/alert';

import { useAuthContext } from './auth';
import { useLoadingContext } from './loading';
import { persist, persistKeys } from './persistor';

export interface User {
  id: string;
  email: string;
  taxId: string;
  name: string;
  bornDate: string;
  lastName?: string;
  gender?: UserGender;
  cellphone?: string;
}

export const defaultUserState: UserContextType = {
  user: {
    id: '',
    email: '',
    taxId: '',
    name: '',
    bornDate: '',
  },
  refresh: () => { },
};

interface UserContextType {
  user: User;
  refresh: () => void;
}

export const UserContext = createContext<UserContextType>(defaultUserState);

type UserPersist = User;

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User>(defaultUserState.user);
  const { auth } = useAuthContext();
  const { data, mutate } = useFetchUser();
  const { add, del } = useLoadingContext();
  const { enqueue } = useAlert();
  const { trigger: logout } = useLogoutMutation();

  const setUserAndPersist = useCallback((newUser: User) => {
    setUser(newUser);
    persist<UserPersist>(persistKeys.user, newUser);
  }, [setUser]);

  const retrieveUser = useCallback(async () => {
    if (auth.isLoggedIn && auth.userId) {
      try {
        add();
        await mutate();
      } catch (error) {
        enqueue(ProfileAlertStates.RETRIEVE_USER_ERROR);
        await logout();
      } finally {
        del();
      }
    }
  }, [add, auth.isLoggedIn, auth.userId, del, enqueue, logout, mutate]);

  useEffect(() => {
    // get default state from local storage
    const userState: UserPersist = JSON.parse(localStorage.getItem(persistKeys.user) ?? '{}');
    if (userState) {
      setUser(userState);
    }
    // get user from API
    retrieveUser();
  }, [retrieveUser]);

  useEffect(() => {
    if (data) {
      setUserAndPersist(data);
    }
  }, [data, setUserAndPersist]);

  return (
    <UserContext.Provider value={{ user, refresh: retrieveUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext<UserContextType>(UserContext);
  if (!context) {
    throw new Error(
      'useUserContext must be used within an UserContextProvider'
    );
  }
  return context;
};


export const useUpdateUser = () => {
  const { enqueue } = useAlert();
  const { trigger, isLoading } = useUpdateUserMutation();
  const { refresh: refreshUser } = useUserContext();

  const update = useCallback(async (data: UpdateUserRequest) => {
    try {
      await trigger(data);
      enqueue(ProfileAlertStates.UPDATE_SUCCESS);
      await refreshUser();
    } catch (error) {
      enqueue(ProfileAlertStates.UPDATE_ERROR);
    }
  }, [enqueue, refreshUser, trigger]);

  return {
    update,
    isLoading,
  }
}

export const useUpdatePassword = () => {
  const { enqueue } = useAlert();
  const { trigger, isLoading } = useUpdatePasswordMutation();

  const update = useCallback(async (data: UpdatePasswordRequest) => {
    try {
      await trigger(data);
      enqueue(ProfileAlertStates.UPDATE_SUCCESS);
    } catch (error) {
      enqueue(ProfileAlertStates.UPDATE_ERROR);
    }
  }, [enqueue, trigger]);

  return {
    update,
    isLoading,
  }
}
