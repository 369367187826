import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useMemo, useState } from 'react';

import apiClient from '../api';
import { ScreenRoutes } from '../router';
import { useAuthContext } from '../state/auth';
import { AuthAlertStates, GenAlertStates } from '../utils/alert';

import useAlert from './useAlert';

export type MutationMethod = 'post' | 'put' | 'delete';

type FetcherFn = <T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  config?: AxiosRequestConfig
) => Promise<AxiosResponse<T>>;

const fetcher: Record<MutationMethod, FetcherFn> = {
  post: async (url, data, config) => apiClient.post(url, data, config),
  put: async (url, data, config) => apiClient.put(url, data, config),
  delete: async (url, _data, config) => apiClient.delete(url, config),
};

interface MutationResponse<T, U> {
  isLoading: boolean;
  error: Error | null;
  trigger: (data?: T, options?: TriggerOptions) => Promise<U>;
}

interface TriggerOptions {
  withFeedback?: boolean;
}

const useMutation = <T = undefined, U = void>(
  url: string,
  method: MutationMethod,
  options?: AxiosRequestConfig
): MutationResponse<T, U> => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { auth: { token }, unauthorize } = useAuthContext();
  const { enqueue } = useAlert();

  const onUnauthorized = useCallback(() => {
    enqueue(AuthAlertStates.UNAUTHORIZED_ERROR);
    window.location.href = ScreenRoutes.HOME;
    unauthorize();
  }, [enqueue, unauthorize]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optionsWithToken = useMemo(
    () => token ? {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`,
        ...(options?.headers || {}),
      },
    } : options || {},
    [token, options]
  );

  const trigger = useCallback(
    async (data?: T, triggerOptions?: TriggerOptions): Promise<U> => {
      setIsLoading(true);
      setError(null);
      try {
        const res = await fetcher[method](url, data, optionsWithToken);
        if (triggerOptions?.withFeedback) {
          enqueue(GenAlertStates[`FEEDBACK_${method.toUpperCase()}_SUCCESS` as keyof typeof GenAlertStates]);
        }
        return res.data as U;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: unknown | AxiosError) {
        if ((err as AxiosError).response?.status === 401) {
          onUnauthorized();
        }
        setError(err as AxiosError);
        if (triggerOptions?.withFeedback) {
          enqueue(GenAlertStates[`FEEDBACK_${method.toUpperCase()}_ERROR` as keyof typeof GenAlertStates]);
        }
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [method, url, optionsWithToken, enqueue, onUnauthorized]
  );

  return {
    isLoading,
    error,
    trigger,
  };
};

export default useMutation;
