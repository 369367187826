import { useCallback, useEffect, useState } from 'react';

import { useOpenLockerMutation } from '../api/lockers';
import useAlert from '../hooks/useAlert';
import { LockerCommand } from '../model/locker';
import { LockerAlertStates } from '../utils/alert';

import { useLoadingContext } from './loading';

export const useOpenLocker = () => {
  const { trigger, isLoading, error } = useOpenLockerMutation();
  const [isOpen, setIsOpen] = useState(false);
  const { add, del } = useLoadingContext();
  const { enqueue } = useAlert();

  useEffect(() => {
    if (isOpen) {
      return enqueue(LockerAlertStates.LOCKER_OPEN_SUCCESS);
    }
  }, [enqueue, isOpen]);

  const openLocker = useCallback(async (id: string) => {
    add();
    try {
      await trigger({ lockerId: id, command: LockerCommand.OPEN });
      setIsOpen(true);
    } catch (error) {
      enqueue(LockerAlertStates.LOCKER_OPEN_ERROR);
    } finally {
      del();
    }
  }, [add, del, enqueue, trigger]);

  return { openLocker, isOpen, error, isLoading };
}