import styled from '@emotion/styled';
import Container from '@mui/material/Container';
import React, { useCallback } from 'react';
import { ScrollRestoration, useNavigate } from 'react-router-dom';

import { ScreenRoutes } from '../router';

import Header from './Header';
import TabNavigation from './TabNavigation';

const TabNavigationPlaceholder = React.memo(styled.div`height: 72px;`);

interface LayoutProps {
  children: React.ReactNode;
  hiddenHeader?: boolean;
  hiddenTabNavigation?: boolean;
  hiddenBackButton?: boolean;
  title?: string;
}

const Layout: React.FC<LayoutProps> = (
  { children, hiddenHeader = false, hiddenTabNavigation = false, hiddenBackButton = false, title }
) => {
  const navigate = useNavigate();

  const handleOnBack = useCallback(() => navigate(ScreenRoutes.GO_BACK), [navigate]);

  return (
    <>
      <ScrollRestoration />
      {!hiddenHeader && <Header hiddenBackButton={hiddenBackButton} title={title} onBack={handleOnBack} />}
      <Container>
        {children}
      </Container>
      {!hiddenTabNavigation && <>
        <TabNavigationPlaceholder />
        <TabNavigation />
      </>}
    </>
  );
};

export default React.memo(Layout);
