import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import * as React from 'react';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}
interface SnackbarAlertProps {
  children?: React.ReactNode;
}

const SnackbarAlertProvider: React.FC<SnackbarAlertProps> = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={3000}
      TransitionComponent={TransitionLeft}
      action={snackbarId => (
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => closeSnackbar(snackbarId)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SnackbarAlertProvider;
