import axios from 'axios';

import env, { Stage } from '../env';

// Create a default Axios instance
const apiClient = axios.create({
  baseURL: 'https://api.haconnector.com',
});

export default apiClient;
