export enum ProfileAlertStates {
  UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  UPDATE_ERROR = 'UPDATE_ERROR',
  RETRIEVE_USER_ERROR = 'RETRIEVE_USER_ERROR',
}

export enum AuthAlertStates {
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_ERROR = 'REGISTER_ERROR',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
}

export enum InformationAlertStates {
  MESSAGE_SUCCESS = 'MESSAGE_SUCCESS',
  MESSAGE_ERROR = 'MESSAGE_ERROR',
}

export enum ProductAlertStates {
  PRODUCT_ERROR = 'PRODUCT_ERROR',
  PRODUCT_OUT_OF_STOCK_ERROR = 'PRODUCT_OUT_OF_STOCK_ERROR',
  PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS',
}

export enum PixAlertStates {
  PIX_COPY_SUCCESS = 'PIX_COPY_SUCCESS',
  PIX_PAYMENT_SUCCESS = 'PIX_PAYMENT_SUCCESS',
  PIX_GENERATE_ERROR = 'PIX_GENERATE_ERROR',
  PIX_TIMEOUT_ERROR = 'PIX_TIMEOUT_ERROR',
}

export enum OrderAlertStates {
  RETRIEVE_ORDER_ERROR = 'RETRIEVE_ORDER_ERROR',
}

export enum CouponAlertStates {
  RETRIEVE_COUPON_ERROR = 'RETRIEVE_COUPON_ERROR',
  UNSELECTED_COUPON_ERROR = 'UNSELECTED_COUPON_ERROR',
}

export enum PosAlertStates {
  RETRIEVE_POS_ERROR = 'RETRIEVE_POS_ERROR',
  POS_SELECTION_NEEDED_ERROR = 'POS_SELECTION_NEEDED_ERROR',
}

export enum CardAlertStates {
  CREATE_SUCCESS = 'CREATE_SUCCESS',
  CREATE_ERROR = 'CREATE_ERROR',
  REMOVE_SUCCESS = 'REMOVE_SUCCESS',
  REMOVE_ERROR = 'REMOVE_ERROR',
  NOT_SELECTED_ERROR = 'NOT_SELECTED_ERROR',
  ENCRYPT_ERROR = 'ENCRYPT_ERROR',
  CREDIT_CARD_ERROR = 'CREDIT_CARD_ERROR',
}

export enum GenAlertStates {
  FEEDBACK_POST_SUCCESS = 'FEEDBACK_POST_SUCCESS',
  FEEDBACK_POST_ERROR = 'FEEDBACK_POST_ERROR',
  FEEDBACK_DELETE_SUCCESS = 'FEEDBACK_DELETE_SUCCESS',
  FEEDBACK_DELETE_ERROR = 'FEEDBACK_DELETE_ERROR',
  FEEDBACK_PUT_SUCCESS = 'FEEDBACK_PUT_SUCCESS',
  FEEDBACK_PUT_ERROR = 'FEEDBACK_PUT_ERROR',
}

export enum LockerAlertStates {
  LOCKER_NOT_LOGGED_IN_ERROR = 'LOCKER_NOT_LOGGED_IN_ERROR',
  LOCKER_NOT_ADULT_ERROR = 'LOCKER_NOT_ADULT_ERROR',
  LOCKER_OPEN_ERROR = 'LOCKER_OPEN_ERROR',
  LOCKER_OPEN_SUCCESS = 'LOCKER_OPEN_SUCCESS',
}

export enum DefaultAlertStates {
  CLOSED = 'CLOSED',
}

export type AlertType =
  ProfileAlertStates |
  AuthAlertStates |
  InformationAlertStates |
  ProductAlertStates |
  PixAlertStates |
  OrderAlertStates |
  CouponAlertStates |
  PosAlertStates |
  DefaultAlertStates |
  GenAlertStates |
  CardAlertStates |
  LockerAlertStates;

export const AlertMessages: Record<AlertType, string> = {
  [ProfileAlertStates.UPDATE_SUCCESS]: 'Atualizado com sucesso',
  [ProfileAlertStates.UPDATE_ERROR]: 'Atualização não pode ser realizada',
  [ProfileAlertStates.RETRIEVE_USER_ERROR]: 'Erro ao carregar usuário',
  [AuthAlertStates.REGISTER_SUCCESS]: 'Registrado com sucesso',
  [AuthAlertStates.REGISTER_ERROR]: 'Registro não pode ser realizado',
  [AuthAlertStates.FORGOT_PASSWORD_SUCCESS]: 'Email enviado com sucesso',
  [AuthAlertStates.FORGOT_PASSWORD_ERROR]: 'Email não pode ser enviado',
  [AuthAlertStates.LOGIN_ERROR]: 'Email ou senha incorretos',
  [AuthAlertStates.LOGIN_SUCCESS]: 'Login realizado com sucesso',
  [AuthAlertStates.UNAUTHORIZED_ERROR]: 'Não autorizado. Faça login novamente ou registre-se',
  [InformationAlertStates.MESSAGE_SUCCESS]: 'Mensagem enviada com sucesso',
  [InformationAlertStates.MESSAGE_ERROR]: 'Erro ao enviar mensagem',
  [ProductAlertStates.PRODUCT_ERROR]: 'Erro ao carregar produtos',
  [ProductAlertStates.PRODUCT_OUT_OF_STOCK_ERROR]: 'Estoque insuficiente',
  [ProductAlertStates.PRODUCT_ADD_SUCCESS]: 'Produto adicionado ao carrinho',
  [PixAlertStates.PIX_COPY_SUCCESS]: 'Pix copiado para área de transferência',
  [PixAlertStates.PIX_PAYMENT_SUCCESS]: 'Pagamento recebido com sucesso',
  [PixAlertStates.PIX_GENERATE_ERROR]: 'Erro ao gerar Pix, tente novamente',
  [PixAlertStates.PIX_TIMEOUT_ERROR]: 'Tempo de pagamento expirado',
  [OrderAlertStates.RETRIEVE_ORDER_ERROR]: 'Erro ao carregar pedidos',
  [CouponAlertStates.RETRIEVE_COUPON_ERROR]: 'Erro ao carregar cupons',
  [CouponAlertStates.UNSELECTED_COUPON_ERROR]: 'Cupom não se aplica',
  [PosAlertStates.RETRIEVE_POS_ERROR]: 'Erro ao carregar pontos de venda',
  [PosAlertStates.POS_SELECTION_NEEDED_ERROR]: 'Selecione um ponto de venda',
  [CardAlertStates.CREATE_SUCCESS]: 'Cartão cadastrado com sucesso',
  [CardAlertStates.CREATE_ERROR]: 'Erro ao cadastrar cartão',
  [CardAlertStates.REMOVE_SUCCESS]: 'Cartão removido com sucesso',
  [CardAlertStates.REMOVE_ERROR]: 'Erro ao remover cartão',
  [CardAlertStates.NOT_SELECTED_ERROR]: 'Selecione um cartão',
  [CardAlertStates.ENCRYPT_ERROR]: 'Cartão com erro, verifique os dados',
  [CardAlertStates.CREDIT_CARD_ERROR]: 'Erro ao processar pagamento',
  [GenAlertStates.FEEDBACK_POST_SUCCESS]: 'Item criado com sucesso',
  [GenAlertStates.FEEDBACK_POST_ERROR]: 'Erro ao criar item',
  [GenAlertStates.FEEDBACK_DELETE_SUCCESS]: 'Item removido com sucesso',
  [GenAlertStates.FEEDBACK_DELETE_ERROR]: 'Erro ao remover item',
  [GenAlertStates.FEEDBACK_PUT_SUCCESS]: 'Item atualizado com sucesso',
  [GenAlertStates.FEEDBACK_PUT_ERROR]: 'Erro ao atualizar item',
  [LockerAlertStates.LOCKER_NOT_LOGGED_IN_ERROR]: 'Faça login para liberar a trava',
  [LockerAlertStates.LOCKER_NOT_ADULT_ERROR]: 'Você precisa ser maior de idade para liberar',
  [LockerAlertStates.LOCKER_OPEN_ERROR]: 'Erro ao abrir a trava',
  [LockerAlertStates.LOCKER_OPEN_SUCCESS]: 'Trava liberada com sucesso',
  [DefaultAlertStates.CLOSED]: '',
};
