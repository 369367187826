import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import useAlert from '../hooks/useAlert';
import { CartItem } from '../model/cart';
import { AMLabsProduct } from '../model/product';
import { ProductAlertStates } from '../utils/alert';
import { getPrice } from '../utils/product';

import { persist, persistKeys } from './persistor';

const defaultCartState: CartContextType = {
  items: {},
  setItems: () => { },
  reset: () => { },
};

type Cart = Record<string, CartItem>;

interface CartContextType {
  items: Cart;
  setItems: (items: Cart) => void;
  reset: () => void;
}

export const CartContext = createContext<CartContextType>(defaultCartState);

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const [items, setItems] = useState<Cart>(defaultCartState.items);

  const setItemsAndPersist = (newItems: Cart) => {
    setItems(newItems);
    persist(persistKeys.cart, newItems, { overwrite: true });
  }

  const reset = useCallback(() => {
    setItemsAndPersist(defaultCartState.items);
  }, []);

  useEffect(() => {
    const cartState = localStorage.getItem(persistKeys.cart);
    const items = cartState ? JSON.parse(cartState) : defaultCartState.items;
    if (cartState) {
      setItems(items);
    }
  }, []);

  return (
    <CartContext.Provider value={{ items, setItems: setItemsAndPersist, reset }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const context = useContext<CartContextType>(CartContext);
  if (!context) {
    throw new Error(
      'useCartContext must be used within an CartContextProvider'
    );
  }
  return context;
};

export interface AddCartItemProps {
  showSuccess?: boolean;
}

export const useAddCartItem = ({ showSuccess }: AddCartItemProps) => {
  const { items, setItems } = useCartContext();
  const { enqueue } = useAlert();

  return (product: AMLabsProduct) => {
    if (product.currentQuantity === 0) {
      return enqueue(ProductAlertStates.PRODUCT_OUT_OF_STOCK_ERROR);
    }

    if (items[product.id] && items[product.id].quantity + 1 > product.currentQuantity) {
      return enqueue(ProductAlertStates.PRODUCT_OUT_OF_STOCK_ERROR);
    }

    if (showSuccess) {
      enqueue(ProductAlertStates.PRODUCT_ADD_SUCCESS);
    }

    const newCart = { ...items };

    if (newCart[product.id] === undefined) {
      newCart[product.id] = {
        quantity: 1,
        product,
      };
    } else {
      newCart[product.id] = {
        ...newCart[product.id],
        quantity: newCart[product.id].quantity + 1,
      }
    }

    setItems(newCart);
  };
};

export const useRemoveCartItem = () => {
  const { items, setItems } = useCartContext();

  return (product: AMLabsProduct) => {
    const newCart = { ...items };

    if (newCart[product.id].quantity === 1) {
      delete newCart[product.id];
    } else {
      newCart[product.id] = {
        ...newCart[product.id],
        quantity: newCart[product.id].quantity - 1,
      }
    }

    setItems(newCart);
  };
}

export const useCartItemsCount = () => {
  const { items } = useCartContext();

  return Object.values(items).reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);
}

export const useCartValue = () => {
  const { items } = useCartContext();

  return Object.values(items).reduce((acc, item) => {
    return acc + (item.quantity * getPrice(item.product));
  }, 0);
}
