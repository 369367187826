import React, { createContext, useCallback, useContext, useState } from 'react';

interface LoadingContextType {
  loading: boolean;
  add: () => void;
  del: () => void;
}

const defaultLoadingState: LoadingContextType = {
  loading: false,
  add: () => { },
  del: () => { },
};

export const LoadingContext = createContext<LoadingContextType>(defaultLoadingState);

export const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [value, setValue] = useState<number>(0);

  const add = useCallback(() => { setValue((prev) => prev + 1); }, []);
  const del = useCallback(() => { setValue((prev) => Math.max(0, prev - 1)); }, []);

  return (
    <LoadingContext.Provider value={{ loading: Boolean(value), add, del }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => {
  const context = useContext<LoadingContextType>(LoadingContext);
  if (!context) {
    throw new Error(
      'useLoadingContext must be used within an LoadingContextProvider'
    );
  }
  return context;
};
