import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';

interface HeaderProps {
  title?: string;
  hiddenBackButton?: boolean;
  onBack: () => void;
}

const Header: React.FC<HeaderProps> = ({ title, onBack, hiddenBackButton = false }) => (
  <AppBar position="static">
    <Toolbar sx={style.toolbar}>
      {!hiddenBackButton && <IconButton
        edge="start"
        color="inherit"
        onClick={onBack}
        sx={style.button}
      >
        <ArrowBackIcon />
      </IconButton>}
      <Typography variant="h6" component="div" sx={style.typography(hiddenBackButton)}>
        {title}
      </Typography>
    </Toolbar>
  </AppBar>
);

const style = {
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',  
  },
  button: {
    marginRight: '16px',
  },
  typography: (hiddenBackButton: boolean) => ({
    paddingRight: hiddenBackButton ? 0 : '40px',
    textAlign: 'center',
    flexGrow: 1,
  })
};

export default React.memo(Header);
