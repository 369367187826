import Container from '@mui/material/Container';
import React from 'react';

import Layout from '../../component/Layout';
import SmartCarousel from '../../component/SmartCarousel';

import Brand from './Brand';
import Featured from './Featured';
import Hero from './Hero';
import MenuList from './MenuList';
import PoSSelector from './PoSSelector';


const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <Layout hiddenHeader>
        <PoSSelector />
        {/* <Container sx={style.featuredContainer}>
          <Featured />
        </Container>
        <Container sx={style.menuListContainer}>
          <SmartCarousel />
        </Container> */}
        <Container sx={style.menuListContainer}>
          <MenuList />
        </Container>
        <Container sx={style.brandContainer}>
          <Brand />
        </Container>
      </Layout>
    </>
  );
};

const style = {
  featuredContainer: {
    padding: 0,
    paddingTop: '52px',
  },
  menuListContainer: {
    padding: 0,
    margin: '32px 0',
  },
  brandContainer: {
    padding: 0,
    margin: '0 0 32px 0',
  },
};

export default React.memo(Home);
