import QrCodeIcon from '@mui/icons-material/QrCode';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useAlert from '../../hooks/useAlert';
import { ScreenRoutes } from '../../router';
import { useAuthContext } from '../../state/auth';
import { useCartItemsCount } from '../../state/cart';
import { usePosContext } from '../../state/pos';
import { useUserContext } from '../../state/user';
import { LockerAlertStates } from '../../utils/alert';
import { posDeviceMap } from '../../utils/locker-devices';
import { namefy } from '../../utils/naming';
import { checkAdultAge } from '../../utils/user';

const Hero: React.FC = () => {
  const navigate = useNavigate();
  const { auth } = useAuthContext();
  const { user } = useUserContext();
  const { enqueue } = useAlert();
  const { selected } = usePosContext();

  const adultAge = useMemo(() => checkAdultAge(user.bornDate), [user.bornDate]);

  const cartItemsCount = useCartItemsCount();

  const welcomeText = useMemo(
    () => auth.isLoggedIn ? namefy(user.name) : 'bem-vindo',
    [auth.isLoggedIn, user.name]
  );

  const handleCartClick = useCallback(() => {
    navigate(ScreenRoutes.CART);
  }, [navigate]);

  const handleQrCodeClick = useCallback(() => {
    // if (!auth.isLoggedIn) {
    //   return enqueue(LockerAlertStates.LOCKER_NOT_LOGGED_IN_ERROR);
    // }
    if (auth.isLoggedIn && !adultAge) {
      return enqueue(LockerAlertStates.LOCKER_NOT_ADULT_ERROR);
    }
    navigate(ScreenRoutes.QR_LOCKER.replace(
      ':id', posDeviceMap[selected?.id as keyof typeof posDeviceMap]
    ));
  }, [adultAge, auth.isLoggedIn, enqueue, navigate, selected?.id]);

  const hasQrCode = useMemo(
    () => !!posDeviceMap[selected?.id as keyof typeof posDeviceMap],
    [selected]
  );

  return (
    <Container sx={style.container}>
      <Grid item xs={12}>
        <Typography variant="h3">Olá,</Typography>
        <Typography variant="h3" sx={style.welcome}>{welcomeText}</Typography>
      </Grid>
      {hasQrCode ? <IconButton
        size='large'
        sx={style.qrButton}
        edge="start"
        color="inherit"
        onClick={handleQrCodeClick}
      >
        <QrCodeIcon />
      </IconButton> : null}
      {/* <IconButton
        size='large'
        sx={style.cartButton}
        edge="start"
        color="inherit"
        onClick={handleCartClick}
      >
        <Badge badgeContent={cartItemsCount} color="error">
          <ShoppingCartIcon />
        </Badge>
      </IconButton> */}
    </Container>
  );
};

const style = {
  container: {
    backgroundColor: 'primary.dark',
    color: 'primary.contrastText',
    height: '320px',
    borderRadius: '0 0 16% 16%',
    paddingTop: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    position: 'relative',
  },
  welcome: {
    fontWeight: 600,
  },
  qrButton: {
    position: 'absolute',
    // right: '60px',
    right: '20px',
    top: '40px',
  },
  cartButton: {
    position: 'absolute',
    right: '20px',
    top: '40px',
  }
};

export default React.memo(Hero);
