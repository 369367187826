export enum LockerCommand {
  OPEN = 'OPEN',
  HEALTHCHECK = 'HEALTHCHECK',
}

export interface LockerRequest {
  lockerId: string
  command: LockerCommand
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>
}

export interface LockerResponse {
  id: string
  error?: string
  command: LockerCommand
  message?: string
}