import { UserGender } from '../model/user';

export class SyntaxValidator {
  private readonly re = {
    id: /^[a-f\d]{24}$/i,
    email: /\S+@\S+\.\S+/,
    cpfTaxId: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
    /**
     * Password must contain at least 8 characters, including at least
     * 1 uppercase letter, 1 lowercase letter, and 1 number.
     * can contain special characters.
     */
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
    name: /^[a-zA-Z]+$/,
    date: /^\d{2}\/\d{2}\/\d{4}$/,
    cellphone: /^\(\d{2}\) \d{5}-\d{4}$/,
    cardNumber: /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/,
    nonempty: /\S+/
  };

  public id = (id: string): boolean => this.re.id.test(id);
  public email = (email: string): boolean => this.re.email.test(email);
  public password = (password: string): boolean => this.re.password.test(password);
  public naming = (name: string): boolean => this.re.name.test(name);
  public date = (date: string): boolean => {
    if (!this.re.date.test(date)) {
      return false;
    }

    const [day, month, year] = date.split('/').map(Number);

    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

    const maxDaysPerMonth = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (month < 1 || month > 12 || day < 1 || day > maxDaysPerMonth[month - 1] || year < 1900) {
      return false;
    }

    return true;
  };


  public cardNumber = (cardNumber: string): boolean => this.re.cardNumber.test(cardNumber);

  public cardExpMonth = (expMonth: string): boolean => {
    const month = Number(expMonth);
    return month >= 1 && month <= 12;
  };

  public cardExpYear = (expYear: string): boolean => {
    const year = Number(expYear);
    return year >= new Date().getFullYear() - 1 && year <= new Date().getFullYear() + 16;
  };

  public cellphone = (cellphone: string): boolean => this.re.cellphone.test(cellphone);
  public nonempty = (str: string): boolean => this.re.nonempty.test(str);
  public gender = (gender: string): boolean =>
    Object.values(UserGender).includes(gender as UserGender);

  public bornDate = (bornDate: string): boolean => {
    if (!this.date(bornDate)) {
      return false;
    }

    const [day, month, year] = bornDate.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    return date < new Date();
  };

  // taxId come as 000.000.000-00
  public cpfTaxId = (taxId: string): boolean => {
    if (!this.re.cpfTaxId.test(taxId)) {
      return false;
    }

    const cpf = taxId.replace(/\D/g, '');

    if (cpf.length !== 11) {
      return false;
    }

    if (cpf === '00000000000') {
      return false;
    }

    let sum = 0;
    let rest;

    for (let i = 1; i <= 9; i++) {
      sum += Number(cpf.substring(i - 1, i)) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== Number(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum += Number(cpf.substring(i - 1, i)) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== Number(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  };
}

export default new SyntaxValidator();
