import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useCallback } from 'react';

import { useCartContext } from '../../state/cart';
import { usePosContext } from '../../state/pos';

const PoSSelectorContainer = React.memo(styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`);

const PoSSelector: React.FC = () => {
  const pos = usePosContext();
  const { reset: resetCart } = useCartContext();

  const handleChange = useCallback((event: SelectChangeEvent) => {
    const posId = event.target.value;
    const selectedPos = pos.list.items.find(({ id }) => id === posId);
    if (selectedPos) {
      pos.select(selectedPos);
      resetCart();
    }
  }, [pos, resetCart]);

  return (
    <PoSSelectorContainer>
      <Typography sx={style.label} variant='caption'>
        Selecione a loja
      </Typography>
      <Select
        onChange={handleChange}
        sx={style.select}
        value={pos.selected?.id ?? ''}
      >
        {pos.list.items.map(({ id, name }) => (
          <MenuItem key={id} value={id}>{name}</MenuItem>
        ))}
      </Select>
    </PoSSelectorContainer>
  );
};

const style = {
  label: {
    position: 'absolute',
    top: '-60px',
    width: '96%',
    textTransform: 'uppercase',
    color: 'custom.white',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  select: {
    width: '96%',
    position: 'absolute',
    backgroundColor: 'custom.white',
    top: '-36px',
    height: '52px',
  }
};

export default React.memo(PoSSelector);


