import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';

import AuthLayout from '../../component/AuthLayout';
import TextField from '../../component/TextField';
import { Mask } from '../../mask';
import CpfTaxIdMask from '../../mask/CpfTaxIdMask';
import DateMask from '../../mask/DateMask';
import { AuthScreenMode } from '../../model/screen';
import { useRegister } from '../../state/auth';
import { globalStyles } from '../../theme';
import syntaxValidator from '../../utils/validator';

export interface RegisterFormData {
  password: { value: string; error: boolean };
  confirmPassword: { value: string; error: boolean };
  name: { value: string; error: boolean };
  email: { value: string; error: boolean };
  taxId: { value: string; error: boolean };
  bornDate: { value: string; error: boolean };
}

interface RegisterProps {
  setAuthScreen: (screenMode: AuthScreenMode) => void;
}

const Register: React.FC<RegisterProps> = ({ setAuthScreen }) => {
  const [formData, setFormData] = useState<RegisterFormData>({
    password: { value: '', error: false },
    confirmPassword: { value: '', error: false },
    name: { value: '', error: false },
    email: { value: '', error: false },
    taxId: { value: '', error: false },
    bornDate: { value: '', error: false },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { isLoading, register } = useRegister({
    onSuccess: () => setAuthScreen('login'),
  });

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: { value, error: false },
    }));
  };

  const handleErrorChange = useCallback((key: keyof RegisterFormData, error: boolean) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: { ...prevFormData[key], error },
    }));
  }, [setFormData]);

  const handleRegister = useCallback(async () => {
    let valid = true;
    if (!syntaxValidator.email(formData.email.value)) {
      handleErrorChange('email', true);
      valid = false;
    }
    if (!syntaxValidator.password(formData.password.value)) {
      handleErrorChange('password', true);
      valid = false;
    }
    if (formData.password.value !== formData.confirmPassword.value) {
      handleErrorChange('confirmPassword', true);
      valid = false;
    }
    if (!syntaxValidator.bornDate(formData.bornDate.value)) {
      handleErrorChange('bornDate', true);
      valid = false;
    }
    if (!syntaxValidator.cpfTaxId(formData.taxId.value)) {
      handleErrorChange('taxId', true);
      valid = false;
    }

    const name = formData.name.value.split(' ')[0];

    if (!syntaxValidator.naming(name)) {
      handleErrorChange('name', true);
      valid = false;
    }

    if (!valid) {
      return;
    }

    const lastName = formData.name.value.split(' ').slice(1).join(' ').trim();

    await register({
      name: name,
      lastName: !lastName ? undefined : lastName,
      email: formData.email.value,
      taxId: formData.taxId.value,
      password: formData.password.value,
      bornDate: formData.bornDate.value,
    });
  }, [
    formData.email.value,
    formData.password.value,
    formData.confirmPassword.value,
    formData.bornDate.value,
    formData.taxId.value,
    formData.name.value,
    register,
    handleErrorChange,
  ]);

  const handleLogin = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAuthScreen('login');
  }, [setAuthScreen]);

  const handleForgotPassword = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAuthScreen('forgot-password');
  }, [setAuthScreen]);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(show => !show);
  }, [setShowPassword]);

  const toggleConfirmPasswordVisibility = useCallback(() => {
    setShowConfirmPassword(show => !show);
  }, [setShowConfirmPassword]);

  const handleCancel = useCallback(() => setAuthScreen(undefined), [setAuthScreen]);

  return (
    <AuthLayout
      onAction={handleRegister}
      title='Registre-se'
      subtitle='Já está registrado?'
      subtitleAction='Entrar'
      onSubtitleAction={handleLogin}
      actionText='Registrar'
      loading={isLoading}
      onCancel={handleCancel}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Nome"
            type="text"
            onChange={handleValueChange}
            name='name'
            value={formData.name.value}
            error={formData.name.error}
            helperText={formData.name.error ? 'Nome inválido' : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Data de nascimento"
            type="text"
            onChange={handleValueChange}
            name="bornDate"
            value={formData.bornDate.value}
            error={formData.bornDate.error}
            helperText={formData.bornDate.error ? 'Data inválida' : ''}
            InputProps={{
              inputComponent: DateMask as Mask,
              inputMode: 'numeric'
            }}
          />
        </Grid>
      </Grid>
      <TextField
        label="Email"
        type="email"
        onChange={handleValueChange}
        name='email'
        value={formData.email.value}
        error={formData.email.error}
        helperText={formData.email.error ? 'E-mail inválido' : ''}
      />
      <TextField
        label="CPF"
        type="text"
        onChange={handleValueChange}
        name='taxId'
        value={formData.taxId.value}
        error={formData.taxId.error}
        helperText={formData.taxId.error ? 'CPF inválido' : ''}
        InputProps={{
          inputComponent: CpfTaxIdMask as Mask,
          inputMode: 'numeric'
        }}
      />
      <TextField
        label="Senha"
        type={showPassword ? 'text' : 'password'}
        onChange={handleValueChange}
        name='password'
        value={formData.password.value}
        error={formData.password.error}
        helperText={formData.password.error ?
          'Senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula e 1 número' :
          ''
        }
        InputProps={{
          endAdornment: (
            <IconButton sx={globalStyles.passwordIcon} size="small" onClick={togglePasswordVisibility} edge="end">
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          ),
        }}
      />
      <TextField
        label="Confirmar Senha"
        type={showConfirmPassword ? 'text' : 'password'}
        onChange={handleValueChange}
        name='confirmPassword'
        value={formData.confirmPassword.value}
        error={formData.confirmPassword.error}
        helperText={formData.confirmPassword.error ? 'Senhas não coincidem' : ''}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={globalStyles.passwordIcon}
              size="small"
              onClick={toggleConfirmPasswordVisibility}
              edge="end"
            >
              {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          ),
        }}
      />
      <Typography sx={style.forgotPassword} variant="caption">
        <Link onClick={handleForgotPassword}>
          Esqueceu a senha?
        </Link>
      </Typography>
    </AuthLayout>
  );
};

const style = {
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '4px',
  },
}

export default React.memo(Register);
