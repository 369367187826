import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useCreateCreditCardOrderMutation,
  useCreatePixOrderMutation,
  useFetchOrders,
  useFetchOrderStatus
} from '../api/orders';
import useAlert from '../hooks/useAlert';
import { CartItem } from '../model/cart';
import { CreatePixOrderResponse, Order, OrderStatus, PaymentType } from '../model/order';
import { IWalletCard } from '../model/wallet';
import { ScreenRoutes } from '../router';
import { CardAlertStates, PixAlertStates } from '../utils/alert';
import { List } from '../utils/data';
import { isExpiredOrder } from '../utils/order';

import { useCouponContext } from './coupon';
import { useLoadingContext } from './loading';
import { persist, persistKeys } from './persistor';
import { usePosContext } from './pos';
import { useUserContext } from './user';

export interface OrderContextType extends OrderPersist {
  select: (newSelected: CreatePixOrderResponse) => void;
  setList: (newList: Order[]) => void;
}

export const defaultOrderState: OrderContextType = {
  current: undefined,
  list: {
    items: [],
    count: 0,
  },
  select: () => { },
  setList: () => { },
};

interface OrderPersist {
  current?: CreatePixOrderResponse;
  list: List<Order>;
}

export const OrderContext = createContext<OrderContextType>(defaultOrderState);

export const OrderProvider = ({ children }: { children: React.ReactNode }) => {
  const [current, setCurrent] = useState<CreatePixOrderResponse | undefined>(undefined);
  const [list, setList] = useState<List<Order>>(defaultOrderState.list);

  const select = useCallback((newCurrent: CreatePixOrderResponse) => {
    setCurrent(newCurrent);
    persist<OrderPersist>(persistKeys.order, { current: newCurrent });
  }, []);

  const setListAndPersist = useCallback((items: Order[]) => {
    const newList = { items: items, count: items.length };
    setList(newList);
    persist<OrderPersist>(persistKeys.order, { list: newList });
  }, []);

  useEffect(() => {
    const orderState: OrderPersist = JSON.parse(localStorage.getItem(persistKeys.order) ?? '{}');
    if (orderState) {
      setCurrent(orderState.current || defaultOrderState.current);
      setList(orderState.list || defaultOrderState.list);
    }
  }, []);


  return (
    <OrderContext.Provider value={{
      current,
      list,
      select,
      setList: setListAndPersist,
    }
    }>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  const context = useContext<OrderContextType>(OrderContext);
  if (!context) {
    throw new Error(
      'useOrderContext must be used within a OrderContextProvider'
    );
  }
  return context;
};

export const useOrderStatusPolling = (
  orderId?: string, expirationDate?: string, onSuccess?: () => void, onExpired?: () => void
) => {
  const { data, mutate } = useFetchOrderStatus(orderId || '');

  // each 10 seconds go through all orders (including the current), and check if they are waiting for payment
  // if they are get order status, keep doing until they are paid or expired
  useEffect(() => {
    if (!orderId || !onSuccess) {
      return;
    }

    let timer: NodeJS.Timeout;
    const pollOrderStatus = async () => {
      try {
        await mutate();
        const status = data?.status
        if (onExpired && expirationDate && isExpiredOrder({ status, expirationDate } as unknown as Order)) {
          onExpired();
        } else if (status === OrderStatus.PAID) {
          onSuccess();
        } else {
          // Order is not paid yet, continue polling
          timer = setTimeout(pollOrderStatus, 10000);
        }
      } catch (e) {
        // Error occurred while getting order status, continue polling
        timer = setTimeout(pollOrderStatus, 10000);
      }
    };

    pollOrderStatus();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [orderId, onSuccess, onExpired, expirationDate, mutate, data?.status]);
}

export const useRefreshOrders = () => {
  const { data, mutate, isLoading } = useFetchOrders();
  const { setList } = useOrderContext();

  useEffect(() => {
    if (data) {
      setList(data);
    }
  }, [data, setList]);

  return {
    refresh: mutate,
    data,
    isLoading
  };
}

export const useCreatePixOrder = () => {
  const { select } = useOrderContext();
  const { add, del } = useLoadingContext();
  const { trigger, isLoading } = useCreatePixOrderMutation();
  const { enqueue } = useAlert();
  const navigate = useNavigate();
  const pos = usePosContext();
  const { user } = useUserContext();
  const coupon = useCouponContext();

  const createPixOrder = async (items: CartItem[]): Promise<void> => {
    try {
      add();
      const res = await trigger({
        user,
        posName: pos.selected?.name ?? '',
        items,
        couponId: coupon.selected?.id,
        type: PaymentType.PIX,
      });
      select(res);
      navigate(ScreenRoutes.PIX_REVIEW);
    } catch (e) {
      enqueue(PixAlertStates.PIX_GENERATE_ERROR);
    } finally {
      del();
    }
  };

  return {
    create: createPixOrder,
    isLoading,
  }
}

export const useCreateCreditCardOrder = () => {
  const { add, del } = useLoadingContext();
  const { trigger, isLoading } = useCreateCreditCardOrderMutation();
  const { enqueue } = useAlert();
  const navigate = useNavigate();
  const pos = usePosContext();
  const { user } = useUserContext();
  const coupon = useCouponContext();

  const createCreditCardOrder = async (items: CartItem[], encryptedCard: string, card: IWalletCard): Promise<void> => {
    try {
      add();
      const res = await trigger({
        user,
        posName: pos.selected?.name ?? '',
        items,
        couponId: coupon.selected?.id,
        type: PaymentType.CREDIT_CARD,
        encryptedCard,
        card,
      });
      navigate(ScreenRoutes.ORDERS, { state: { orderId: res.orderId } });
    } catch (e) {
      enqueue(CardAlertStates.CREDIT_CARD_ERROR);
    } finally {
      del();
    }
  };

  return {
    create: createCreditCardOrder,
    isLoading,
  }
}