import { AxiosError, AxiosRequestConfig } from 'axios';
import { useCallback, useMemo } from 'react';
import useSWR, { BareFetcher, SWRConfiguration, SWRResponse } from 'swr';

import apiClient from '../api';
import { ScreenRoutes } from '../router';
import { useAuthContext } from '../state/auth';
import { AuthAlertStates } from '../utils/alert';

import useAlert from './useAlert';

interface FetcherOptions {
  onUnauthorized?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function fetcher<T>(url: string, params?: AxiosRequestConfig<any>, options?: FetcherOptions) {
  try {
    const res = await apiClient.get<T>(url, params);
    return res.data;
  } catch (error: unknown | AxiosError) {
    if ((error as AxiosError).response?.status === 401 && options?.onUnauthorized) {
      options.onUnauthorized();
    }
    throw error;
  }
}

const supportSWRMultipleArguments = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customFetcher: (url: string, params: Record<string, any>, options?: FetcherOptions) => Promise<any>,
  hookName:
    | 'useFetch'
    | 'useInfiniteFetch'
    | 'useInfiniteCursorFetch' = 'useFetch',
  fetcherOptions?: FetcherOptions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): BareFetcher<any> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ([url, params]: [string, Record<string, any>]) => {
    if (!url || typeof url !== 'string') {
      console.error(
        `[${hookName}]: Url not found. ` +
        'Could not find a valid url in the arguments'
      );
    }

    return customFetcher(url, params, fetcherOptions);
  };
};

const useFetch = <T>(
  url: string | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>,
  options?: SWRConfiguration,
  customFetcher?: (url: string) => Promise<T>
): SWRResponse<T> => {
  const { auth: { token }, unauthorize } = useAuthContext();
  const { enqueue } = useAlert();

  const paramsWithToken = useMemo(() => ({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { ...(params || {}) },
  }), [params, token]);

  const onUnauthorized = useCallback(() => {
    enqueue(AuthAlertStates.UNAUTHORIZED_ERROR);
    window.location.href = ScreenRoutes.HOME;
    unauthorize();
  }, [enqueue, unauthorize]);

  const fetcherOptions = useMemo(() => {
    return {
      onUnauthorized,
    };
  }, [onUnauthorized]);


  const swr = useSWR<T>(
    // conditional fetching
    url ? [url, paramsWithToken] : null,
    supportSWRMultipleArguments(customFetcher || fetcher, 'useFetch', fetcherOptions),
    {
      dedupingInterval: 60 * 60 * 1000,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      ...options,
    }
  );

  return swr;
};

export default useFetch;
