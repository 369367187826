import EmailIcon from '@mui/icons-material/Email';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { useState, useCallback, useMemo } from 'react';

import Layout from '../../component/Layout';
import TextField from '../../component/TextField';
import useAlert from '../../hooks/useAlert';
import { useAuthContext } from '../../state/auth';
import { useCreateContactMessage } from '../../state/contact';
import { usePosContext } from '../../state/pos';
import { globalStyles } from '../../theme';
import { PosAlertStates } from '../../utils/alert';
import { EventHub, MappedEvents } from '../../utils/event';

const Information = () => {
  const [message, setMessage] = useState('');
  const pos = usePosContext();
  const { enqueue } = useAlert();
  const { auth } = useAuthContext();
  const { send, isLoading } = useCreateContactMessage();

  const emailAction = useCallback(() => {
    window.open('mailto:homemarketpb@gmail.com', '_blank');
  }, []);

  const whatsappAction = useCallback(() => {
    window.open('https://wa.me/message/ICZHC7ZCQCOGO1', '_blank');
  }, []);

  const handleMessageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (!auth.isLoggedIn) {
      EventHub.emit(MappedEvents.LoginAttempt);
    }

    if (!pos.selected) {
      return enqueue(PosAlertStates.POS_SELECTION_NEEDED_ERROR);
    }

    await send(message);
  }, [auth.isLoggedIn, enqueue, message, pos.selected, send]);

  const listItems = useMemo(() => [
    {
      id: 2,
      icon: <EmailIcon />,
      text: 'homemarketpb@gmail.com',
      action: emailAction,
    },
    {
      id: 3,
      icon: <WhatsappIcon />,
      text: '(83) 99131-7500',
      action: whatsappAction,
    },
  ], [emailAction, whatsappAction]);

  return (
    <Layout title='Centro de Informações' hiddenTabNavigation>
      <Container sx={style.logoContainer}>
        <img style={style.image} src='./assets/partnership-logo.png' alt='partnership' />
      </Container>
      <Typography sx={style.text} variant='body2'>
        A Central de Informações está disponível para orientações, esclarecimento de dúvidas e sugestões
        {' '}envolvendo a utilização dos serviços. Gostaria de acrescentar algum produto? Aqui é o lugar! <br /> <br />
        Caso possua dúvidas sobre como cuidamos de seus dados, acesse nossa
        {' '}<Link href="https://sennela.com/haconnector/privacy/index.html" target="_blank">
          Política de Privacidade
        </Link>.
      </Typography>
      <Box sx={globalStyles.section}>
        <Typography sx={globalStyles.sectionTitle} variant='h6'>Contato</Typography>
        <TextField
          id="outlined-multiline-static"
          placeholder='Digite sua dúvida, sugestão ou reclamação.'
          multiline
          rows={4}
          variant='standard'
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={style.input}
          value={message}
          onChange={handleMessageChange}
        />
        <Button
          variant="contained"
          fullWidth
          disableElevation
          sx={globalStyles.radiusButton}
          onClick={handleSendMessage}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={globalStyles.circularProgress.size} /> : 'Enviar'}
        </Button>
      </Box>
      <Box sx={globalStyles.section}>
        <Typography sx={globalStyles.sectionTitle} variant='h6'>Fale Conosco</Typography>
        <List sx={style.list}>
          {listItems.map((item) => (
            <ListItem disablePadding key={item.id}>
              <ListItemButton onClick={item.action}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Layout>
  );
};

const style = {
  image: {
    maxWidth: '180px',
    margin: '16px 0',
  },
  text: {
    marginBottom: '40px',
    textAlign: 'justify',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '24px 0 16px 0',
  },
  input: {
    marginBottom: '16px',
  },
  list: {
    padding: 0,
  },
};

export default React.memo(Information);
